.removeElAnim {
    opacity: 0;
    transition: opacity 0.1s ease;
}

.square {
    position: absolute;
    width: 50px;
    height: 50px;
    opacity: 0;
    transition: opacity 0.1s ease;
}

.square.active {
    opacity: 1;
}

@keyframes opacityAnim {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.animElem {
    animation-name: opacityAnim;
    animation-duration: 100;
}
