@import "../../variables.scss";

.card {
  width: 100%;
  height: 226px;
  background: #ffffff;
  display: flex;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.06);
    transform: translateY(-5px);
  }

  .hoverfill {
    transition: fill 0.3s ease-in-out;
  }
  &:hover .hoverfill {
    fill: $color-primary;
  }

  svg {
    width: 201px;
    height: 100%;
    display: block;
    top: 0;
    right: 0;
    position: absolute;
    overflow: hidden;
    margin: 0;
  }
}

.info {
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: #242424;
    margin: 0;
    margin-bottom: 8px;
  }

  p {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #242424;
    margin: 0;
    margin-right: 98px;
    margin-bottom: 0px;
    flex: 1;
  }

  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #242424;
    margin: 0;
  }
}

@media (max-width: 767px) {
  .card {
    width: 22.5rem;
    height: 12rem;
  
    svg {
      width: auto;
      height: 100%;
    }
  }

  .info {
    padding: 4%;

    h3 {
      font-size: 1.6rem;
      line-height: 2.5rem;
      margin-bottom: 1%;
    }

    p {
      font-size: 1rem;
      line-height: 1.6rem;
      margin-right: 20%;
    }

    span {
      font-size: 1rem;
      line-height: 2rem;
    }
  }
}
