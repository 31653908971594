.logoButton {
    font-family: "Sono";
    font-weight: 400;
    font-size: 20px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    color: black !important;
    svg {
        margin-left: 4px;
        margin-right: 4px;
    }
}
