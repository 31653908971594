@import "../../variables.scss";

@keyframes inputErrorAnimation {
  0%,
  100% {
    border-color: initial;
    background-color: initial;
  }
  50% {
    border-color: white;
    background-color: rgba(241, 138, 138, 0.697);
    color: white;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.request {
  display: flex;
  flex-direction: column;

  .requestTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;

    h2 {
      margin: 0;
      margin-bottom: 5px;
      display: flex;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      color: black;
    }

    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;

      path {
        transition: all 0.3s ease-in-out;
      }

      &:hover path {
        stroke: #c8efda;
      }
    }
  }
}

.contactForm {
  height: 673px;
  width: 440px;
  background: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;

  input {
    width: 398px;
    height: 47px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    padding: 10px;
    border: 1px solid $color-text;

    &::placeholder {
      color: $color-placeholder;
    }

    &:focus {
      transition: border-color 0.6s ease-in-out;
      border-color: $color-primary;
    }
    transition: border-color 0.3s ease-in-out;

    &:hover {
      border-color: $color-primary;
    }
  }

  .requestInput {
    width: 398px;
    height: 200px;
    margin-bottom: 20px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    padding: 10px;
    border: 1px solid $color-text;
    resize: none;
    transition: border-color 0.6s ease-in-out;
    flex: 1;

    &:hover {
      border-color: $color-primary;
    }

    &::placeholder {
      color: $color-placeholder;
    }
    &:focus {
      transition: border-color 0.6s ease-in-out;
      border-color: $color-primary;
    }
  }

  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    margin: 0;
    align-self: flex-start;
  }

  button {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    width: 190px;
    height: auto;
    padding: 8px;
    cursor: pointer;
    background-color: #c8efda;
    border: 0;
    align-self: flex-start;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: $color-hover;
    }
  }
}

.contactForm {
  .secondP {
    margin-bottom: 17px;
  }
  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  input {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.errorInput {
  animation: inputErrorAnimation 1.2s ease-in-out;
}

.loadingIcon {
  display: inline-block;
  border: 5px solid $color-primary;
  border-left-color: black;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  animation: loading 1s linear infinite;
  margin-top: 133px;
}

.sentRequest {
  padding-top: 133px;
  h4 {
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    margin-top: 40px;
    animation: zoomIn 0.5s ease-in-out;
  }
  svg {
    animation: fadeIn 0.5s ease-in-out;
  }
}

@media (max-width: 767px) {
  .request {
    .requestTop {
      margin-top: 3vh;
      margin-bottom: 4vh;

      h2 {
        font-size: 2.3rem;
        line-height: 0rem;
        margin-bottom: 0.8vh;
      }

      svg {
        width: 1.9rem;
        height: 1.9rem;
        cursor: pointer;

        path {
          transition: all 0.3s ease-in-out;
          stroke-width: 2;
        }

        &:hover path {
          stroke: #c8efda;
        }
      }
    }
  }

  .contactForm {
    height: 72vh;
    width: 90vw;
    padding: 4vw;
    padding-top: 1vh;
    margin-bottom: 8vh;

    input {
      width: 100%;
      height: 5vh;
      font-size: 1.1rem;
      padding: 3%;
      border-radius: 0;
    }

    .requestInput {
      width: 100%;
      margin-bottom: 2vh;
      font-size: 1.1rem;
      padding: 3%;
      border-radius: 0;
    }

    h3 {
      font-size: 1.6rem;
      line-height: 2rem;
      margin: 0;
    }

    button {
      font-size: 1rem;
      width: auto;
      height: auto;
      padding: 0.5vh;
      padding-left: 10vw;
      padding-right: 10vw;
      color: #000000;

      &:hover {
        background-color: $color-primary;
      }
    }
  }

  .contactForm {
    .secondP {
      margin-bottom: 2vh;
    }
    h3 {
      margin-top: 1vh;
      margin-bottom: 1vh;
    }
    input {
      margin-top: 0.8vh;
      margin-bottom: 0.8vh;
    }
  }

  .loadingIcon {
    width: 40vw;
    height: 40vw;
    margin-top: 15vh;
  }

  .sentRequest {
    padding-top: 15vh;
    h4 {
      font-size: 1.8rem;
      margin-top: 5vh;
    }
  }
}
