@import "../../variables.scss";

@keyframes moveDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(3px);
    }
}

@keyframes moveUp {
    from {
        transform: translateY(3px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes blurOut {
    from {
        opacity: 1;
        filter: blur(0);
    }
    to {
        opacity: 0;
        filter: blur(4px);
    }
}

@keyframes blurIn {
    from {
        opacity: 0;
        filter: blur(4px);
    }
    to {
        opacity: 1;
        filter: blur(0);
    }
}

.header {
    display: flex;
    justify-content: space-between;
    margin-left: 35px;
    margin-right: 150px;
    align-items: center;
    padding: 20px;

    &Left {
        button {
            font-family: "Sono";
            font-weight: 400;
            font-size: 20px;
            cursor: pointer;
            background-color: transparent;
            border: 0;
            padding: 0;
            display: flex;
            align-items: center;

            svg {
                margin-left: 4px;
                margin-right: 4px;
            }
        }
    }

    &Center {
        display: flex;
        justify-content: right;
        align-items: center;
        flex: 1;
        // max-width: 500px;
        margin-right: auto;

        a {
            margin: 10px;
        }

        button {
            width: 133px;
            height: 44px;
            cursor: pointer;
            background-color: transparent;
            border: 0;
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            text-align: center;
            transition: background-color 0.3s ease-in-out;
            padding: 0;

            &:hover {
                background-color: rgba($color-hover, 0.5);
            }

            &:hover span {
                opacity: 100%;
            }

            &.selected {
                background-color: $color-hover;
            }
        }
    }
}
