@import "../../variables.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    h2 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        text-align: center;
        color: $color-text;
        @media (max-width: 767px) {
            font-size: 1.6rem;
            line-height: 2.4rem;
            margin: 0;
            margin-top: 20px;
        }
    }
    .taskContainer {
        max-width: 850px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 27px;
        margin-top: 60px;
        @media (max-width: 767px) {
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
            margin-top: 1.5rem;
        }

        .task {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 38px;
            background-color: $color-hover;
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            padding: 4px 28px;
            color: $color-text;
            border-radius: 12px;
            transition: transform 0.3s ease-out;
            &:hover {
                transform: scale(1.2);
            }
            @media (max-width: 767px) {
                height: 29px;
                font-size: 14px;
                line-height: 21px;
                padding: 4px 12px;
                border-radius: 8px;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}
