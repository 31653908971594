.headerMobile {
  height: 9.5vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  // backdrop-filter: blur(5px); doesn't work idk why
  opacity: 1;
  background-color: #f5f5f5;
}

.headerLeft {
  button {
    margin-left: 5vw;
    height: 1.5rem;
    font-family: "Sono";
    font-weight: 400;
    font-size: 1.32rem;
    background-color: transparent;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    color: #000000 !important;

    svg {
      width: 0.2rem;
      height: 0.2rem;
      margin-left: 1.5vw;
      margin-right: 1.5vw;
    }
  }
}

.headerRight {
  margin-right: 5vw;
  background-color: transparent;
  border: 0;
  padding: 0;

  svg {
    width: 2rem;
    height: 2rem;
  }
}
