@import "../../variables.scss";

.contacts {
    li {
        display: flex;
        margin-bottom: 24px;
        font-family: "Manrope";

        svg {
            margin-right: 24px;
            cursor: pointer;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: $color-primary;
            }
        }
    }

    h2 {
        font-size: 40px;
        margin-bottom: 40px;
        color: black;
    }

    p {
        font-weight: 400;
        font-size: 20px;
        margin: 0;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: $color-primary;
        }
    }
}

@media (max-width: 767px) {
    .contacts {
        margin-bottom: 14vh;

        li {
            margin-bottom: 3vh;

            svg {
                margin-right: 6vw;
            }
        }

        h2 {
            font-size: 2.3rem;
            margin-bottom: 4vh;
        }

        p {
            font-size: 1rem;
        }
    }
}
