@import "../../variables.scss";


@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animateRequest {
  animation-name: fadeInScale;
  animation-duration: 2s;
  animation-fill-mode: both;
}


.homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 220px;
  margin-right: 220px;
  margin-top: 130px;

  button {
    align-self: flex-start;
    width: 279px;
    height: 64px;
    cursor: pointer;
    background-color: $color-primary;
    border: 0;
    font-family: "Manrope";
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: $color-hover;
    }
  }

  h1 {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 80px;
    line-height: 110px;
    margin: 0;
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 4vw;
  }
  .homePage {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 20vh + 9.5vh;
  
    button {
      align-self: flex-start;
      width: auto;
      height: auto;
      cursor: pointer;
      background-color: $color-primary;
      border: 0;
      font-family: "Manrope";
      padding: 6vw;
      padding-top: 2vw;
      padding-bottom: 2vw;
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      color: #000000 !important;
      transition: background-color 0.3s ease-in-out;
    }
  
    h1 {
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 2.5rem;
      line-height: 3.6rem;
      margin: 0;
      margin-bottom: 2rem;
    }
  }
}