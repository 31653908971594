.orderWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 120px 20px;
    @media (max-width: 767px) {
        margin: 40px 20px 0 20px;
    }
    .arrow {
        position: relative;
        width: 129px;
        height: 126px;
        @media (max-width: 767px) {
            margin-right: 30px;
        }
        @media (max-width: 375px) {
            margin-right: 0px;
        }
        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 129px;
            height: 126px;
            @media (max-width: 767px) {
                width: 87px;
                height: 87px;
            }
        }
    }
    p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        width: 760px;
        padding-left: 35px;
        margin: 0;
        @media (max-width: 767px) {
            font-size: 1rem;
            line-height: 1.5rem;
        }
        @media (max-width: 375px) {
            width: 217px;
            padding-left: 0px;
        }
    }
}
