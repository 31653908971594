@import "../../variables.scss";

@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.animateRequest {
    animation-name: fadeInScale;
    animation-duration: 2s;
    animation-fill-mode: both;
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 150;
    backdrop-filter: blur(5px);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.visible {
    visibility: visible;
    opacity: 1;
}

.drawer {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 560px;
    height: 100%;
    right: 0;
    padding-top: 40px;
    background: #f5f5f5;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    overflow: auto;
}

.opened {
    transform: translateX(0);
}

@media (max-width: 767px) {
    .overlay {
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        backdrop-filter: initial;
    }

    .drawer {
        z-index: 1000;
        padding: 0;
        width: 100%;
        height: 100%;
    }
}
