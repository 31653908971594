@import "../../variables.scss";

.wrapper {
    margin-left: 220px;
    margin-right: 220px;
    margin-top: 60px;
}

h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #242424;
    margin: 0;
}

.description {
    display: flex;
    flex-direction: column;

    .container1 {
        .line1,
        .line2 {
            text-align: left;
            display: inline;
        }

        .line3 {
            text-align: right;
            display: block;
        }
    }

    .container2 {
        .line4,
        .line5 {
            display: inline;
        }
    }

    p {
        margin-left: 280px;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 40px;
        color: #242424;
        margin-bottom: 80px;

        &.leftAlign {
            margin-left: 0px;
            margin-bottom: 80px;
        }
    }
}

.ourTeam {
    margin-top: 40px;
    display: flex;
    margin-bottom: 120px;
    justify-content: space-between;

    .memberCardWrapper {
        display: flex;
    }

    .memberCard {
        width: 220px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-shrink: 0;
        padding: 12px;
        padding-bottom: 20px;
        margin-right: 40px;

        img {
            box-shadow: 0px 0px 15px 12px rgba(0, 0, 0, 0.1);
            width: auto;
            height: 250px;
            object-fit: cover;
            overflow: hidden;
            margin-bottom: 12px;
        }

        h6 {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            margin-top: 0px;
            margin-bottom: 10px;
        }

        span {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #5f5f5f;
        }
    }

    p {
        margin: 0;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #242424;
        margin-right: 70px;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        button {
            background: transparent;
            color: #242424;
            border: 0;
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            text-align: right;
            padding-left: 20px;
            padding-right: 20px;
            height: 48px;
            width: auto;
            white-space: nowrap;
            border-radius: 0px;
            margin-bottom: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;

            &:hover {
                background: #d6e9ff;
                background-color: $color-hover;
            }

            &.selected {
                background-color: #c8efda;
                font-weight: 600;
            }

            &:last-child {
                width: auto;
            }
        }
    }
}

.principlesTitle {
    margin-bottom: 60px;
}

.principles {
    display: flex;
    flex-direction: column;
    margin-bottom: 120px;

    h3,
    span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        color: #242424;
        margin: 0;
    }

    .partOpacity0 {
        opacity: 0;
    }

    .part {
        display: flex;

        span {
            margin-right: 15px;
        }

        h3 {
            margin-bottom: 15px;
        }

        p {
            margin: 0px;
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;

            &:last-child {
                margin-bottom: 40px;
            }
        }
    }

    .part2 {
        display: flex;
        justify-content: center;
    }

    .part3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.contacts {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 767px) {
    html {
        font-size: 4vw;
    }

    .wrapper {
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 3vh + 9.5vh;
    }

    h2 {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 0;
    }

    .description {
        display: flex;
        flex-direction: column;

        .container1 {
            .line1,
            .line2,
            .line3 {
                display: block;
            }

            .line1 {
                text-align: left;
            }

            .line2 {
                text-align: right;
            }

            .line3 {
                text-align: center;
            }
        }

        .container2 {
            .line4,
            .line5 {
                display: block;
            }

            .line5 {
                text-align: right;
            }
        }

        p {
            margin-left: 0;
            font-size: 1.1rem;
            line-height: 1.6rem;
            margin: 0;
            margin-top: 3vh;
            margin-bottom: 6vh;

            &.leftAlign {
                margin-left: 0;
                margin-bottom: 6vh;
            }
        }
    }

    .ourTeam {
        margin-top: 1.4rem;
        margin-bottom: 3rem;
        flex-direction: column-reverse;

        .memberCard {
            width: 50%;
            height: auto;
            background: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-self: center;
            flex-shrink: 0;
            margin: 0;
            padding: 2.5%;

            img {
                box-shadow: 0px 0px 4vw 1.2vw rgba(0, 0, 0, 0.1);
                width: auto;
                height: 12rem;
                margin-bottom: 0.5rem;
            }

            h6 {
                font-size: 1.3rem;
                margin-bottom: 0.4rem;
                line-height: 1.8rem;
            }

            span {
                font-family: "Manrope";
                font-style: normal;
                font-weight: 400;
                font-size: 0.9rem;
                line-height: 1.5rem;
                white-space: nowrap;
                color: #5f5f5f;
            }
        }

        p {
            margin: 0;
            font-size: 1rem;
            line-height: 1.6rem;
            margin-bottom: 0vh;
            margin-left: 1.2rem;
        }

        .buttons {
            margin-left: 0vw;
            margin-bottom: 1rem;
            align-items: flex-start;

            button {
                font-size: 1rem;
                padding-left: 1rem;
                padding-right: 1rem;
                padding-top: 0.4rem;
                padding-bottom: 0.4rem;
                height: auto;
                width: auto;
                margin-bottom: 0.5rem;
            }
        }
    }

    .principlesTitle {
        margin-bottom: 2vh;
    }
    .principles {
        display: flex;
        flex-direction: column;
        margin-bottom: 3vh;

        h3,
        span {
            font-size: 1.25rem;
        }

        h3 {
            margin-left: 2vw;
        }

        .partOpacity0 {
            opacity: 0;
        }

        .part {
            display: flex;

            span {
                margin-right: 0px;
            }

            h3 {
                margin-bottom: 1vh;
            }

            p {
                margin-left: 9px;
                font-size: 1rem;
                line-height: 1.3rem;

                &:last-child {
                    margin-bottom: 2vh;
                }
            }
        }

        .part2 {
            display: flex;
            justify-content: baseline;
            align-self: start;
        }

        .part3 {
            display: flex;
            flex-direction: column-reverse;
            align-self: start;
            align-items: flex-start;

            svg {
                margin-top: 3vh;
                width: 25vw;
            }
        }
    }

    .contacts {
        display: flex;
        flex-direction: column-reverse;
    }
}
