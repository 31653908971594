// Переменные цветов
@import "./variables.scss";

html {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
}

body {
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $color-background;
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    outline: none;
}

a {
    color: #000000 !important;
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}
