@import "../../variables.scss";

footer {
    padding-top: 280px;
    margin-top: auto;

    ul {
        display: flex;
        gap: 80px;
        padding: 40px;
        justify-content: center;
        @media (max-width: 767px) {
            gap: 20px;
        }
    }
    li {
        margin: 0;
        transition: color 0.3s ease-in-out;
        font-size: 20px;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        &:hover {
            color: $color-primary;
        }
    }
    .handleInstagram {
        display: flex;
        gap: 8px;
        align-items: center;
        svg {
            stroke: $color-text;
            transition: stroke 0.3s ease-in-out;
        }
        &:hover {
            color: $color-primary;
            svg {
                stroke: $color-primary;
            }
        }
    }
}
