.contacts {
    display: flex;
    justify-content: space-between;
    margin-left: 220px;
    margin-right: 220px;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
        margin: 0 20px;
    }
    .requestWrapper {
        margin-left: 2rem;
        @media (max-width: 767px) {
            margin-left: 0;
        }
    }
}
