@import "../../variables.scss";

.homePage {
    height: calc(100vh - 25vh);
    min-width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 220px;
    margin-right: 220px;
    @media (min-width: 767px) and (max-width: 1024px) {
        margin-top: 0px;
        margin-left: 10vw;
        height: calc(100vh - 90px);
    }
    @media (max-width: 767px) {
        margin-top: 0px;
        margin-left: 5vw;
        margin-right: 5vw;
        height: calc(100vh - 50px);
    }
    .titleButton {
        align-self: flex-start;
        width: 279px;
        height: 64px;
        cursor: pointer;
        background-color: $color-primary;
        color: black !important;
        border: 0;
        font-family: "Manrope";
        padding: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        transition: background-color 0.3s ease-in-out;
        &:hover {
            background-color: $color-hover;
        }
        @media (max-width: 767px) {
            width: auto;
            height: auto;
            padding: 6vw;
            padding-top: 1vw;
            padding-bottom: 1vw;
            font-size: 1.5rem;
            margin-bottom: 0;
        }
    }
    .homeTitle {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 80px;
        line-height: 110px;
        margin: 0;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            margin-top: 0;
            font-size: 2.5rem;
            line-height: 3.6rem;
            margin-bottom: 2rem;
        }
    }
}
