@keyframes fadeIn {
  0% {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn 1s ease-out forwards;
}
