@import "../../variables.scss";

.marqueeWrapper {
    margin: auto -1000px;
    @media (min-width: 3440px) {
        margin: auto -3000px;
    }
    @media (max-width: 767px) {
        margin: 0;
    }
    .marquee {
        height: 80px;
        user-select: none;
        background-color: $color-hover;
        @media (max-width: 767px) {
            height: 40px;
        }
    }
    .marqueeItem {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 30px;
        color: $color-text;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
        }
        .bigPoint {
            background-color: black;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin: 0 24px;
            @media (max-width: 767px) {
                width: 8px;
                height: 8px;
                margin: 0 12px;
            }
        }
        .smallPoint {
            background-color: black;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin: 0 24px;
            @media (max-width: 767px) {
                margin: 0 12px;
            }
        }
    }
}
