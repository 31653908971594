@import "../../variables.scss";

.wrapper {
    margin-left: 220px;
    margin-right: 220px;
    margin-top: 120px;
}

h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: $color-text;
    margin: 0;
}

.description {
    display: flex;
    flex-direction: column;

    .container1 {
        .line1,
        .line2 {
            text-align: left;
            display: inline;
        }

        .line3 {
            text-align: right;
            display: block;
        }
    }

    .container2 {
        .line4,
        .line5 {
            display: inline;
        }
    }

    p {
        margin-left: 280px;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 40px;
        color: $color-text;
        margin-bottom: 80px;

        &.leftAlign {
            margin-left: 0px;
            margin-bottom: 80px;
        }
    }
}

.principlesTitle {
    margin-bottom: 60px;
}

.principles {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    h3,
    span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        color: $color-text;
        margin: 0;
    }
    .partOpacity0 {
        opacity: 0;
    }
    .part {
        display: flex;

        span {
            margin-right: 15px;
        }
        h3 {
            margin-bottom: 15px;
        }
        p {
            margin: 0px;
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;
            &:last-child {
                margin-bottom: 40px;
            }
        }
    }
    .part2 {
        display: flex;
        justify-content: center;
    }
    .part3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media (max-width: 767px) {
    html {
        font-size: 4vw;
    }
    br {
        display: none;
    }

    .wrapper {
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 8vh;
    }

    h2 {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 0;
    }

    .description {
        display: flex;
        flex-direction: column;

        .container1 {
            .line1,
            .line2,
            .line3 {
                display: block;
            }
            .line1 {
                text-align: left;
            }

            .line2 {
                text-align: right;
            }

            .line3 {
                text-align: center;
            }
        }

        .container2 {
            .line4,
            .line5 {
                display: block;
            }
            .line5 {
                text-align: right;
            }
        }

        p {
            margin-left: 0;
            font-size: 1.1rem;
            line-height: 1.3rem;
            margin: 0;
            margin-top: 3vh;
            margin-bottom: 6vh;
            &.leftAlign {
                margin-left: 0;
                margin-bottom: 6vh;
            }
        }
    }

    .principlesTitle {
        margin-bottom: 1.5rem;
        line-height: 30px;
    }
    .principles {
        display: flex;
        flex-direction: column;
        margin-bottom: 3vh;

        h3,
        span {
            font-size: 1.25rem;
            line-height: 30px;
        }

        .partOpacity0 {
            opacity: 0;
        }

        .part {
            display: flex;

            span {
                line-height: 2rem;
            }
            h3 {
                margin-bottom: 2vh;
                line-height: 2rem;
            }
            p {
                margin-left: 0vh;
                font-size: 1rem;
                line-height: 1.5rem;
                &:last-child {
                    margin-bottom: 3vh;
                }
            }
        }
        .part2 {
            display: flex;
            justify-content: baseline;
            align-self: start;
        }
        .part3 {
            display: flex;
            flex-direction: column-reverse;
            align-self: start;
            align-items: flex-start;
            svg {
                margin-top: 3vh;
                width: 25vw;
            }
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
@media (max-width: 390px) {
    .wrapper {
        margin-top: 48px;
    }
}
