@import "../../variables.scss";

.header {
    display: flex;
    justify-content: space-between;
    margin-left: 35px;
    margin-right: 35px;
    align-items: center;
    padding: 29px 20px 20px;
    @media (max-width: 767px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        opacity: 1;
        background-color: $color-background;
        height: 56px;
        width: 100%;
        margin-left: 0;
    }
    &Center {
        a {
            margin: 10px;
            @media (max-width: 767px) {
                margin: 0;
            }
        }
        button {
            width: 197px;
            height: 46px;
            cursor: pointer;
            background-color: transparent;
            border: 2px solid $color-hover;
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            transition: background-color 0.3s ease-in-out;
            padding: 0;
            color: black !important;
            &:hover {
                background-color: $color-hover;
            }
            &:hover span {
                opacity: 100%;
            }
            &.selected {
                background-color: $color-hover;
            }
            @media (max-width: 767px) {
                width: 126px;
                height: 29px;
                font-size: 14px;
                line-height: 21px;
                margin: 0;
            }
        }
    }
}
